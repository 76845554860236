import gogApi from './gogApi';
import defAlbums from '../consts/defaultAlbums';

const getDefaultAlbumsIds = (newIds: boolean) => {
  if (newIds) {
    return [
      'd9522d0a-e9e1-4946-852b-4ab854862cbc',
      '05cf65d0-109b-4e8a-8aa1-f87e8e30469d',
      '10f9ad9a-7f87-461a-93fa-bb8a77b474a6',
    ];
  }
  return [
    '590154da-37f0-415a-bfad-9340892925e5',
    'c50ffb1c-66a8-4c88-b66b-4c2f39c11f38',
    '3bd21aa6-8832-4ce8-a81b-4598698c965b',
  ];
};

const defaultAlbumsService = (httpClient: any, isViewer: boolean = false) => {
  const { getGogAlbums, getMasterGogData } = gogApi(httpClient);

  const getDefaultAlbums = async (
    defaultAlbumsIds: string[],
    instance: string,
    newAlbums: boolean,
  ) => {
    if (defaultAlbumsIds && defaultAlbumsIds.length > 0) {
      return fetchTemplateDefaultAlbums(defaultAlbumsIds, instance);
    }
    return fetchDefaultAlbums(newAlbums);
  };

  const fetchDefaultAlbums = async (newAlbums: boolean) => {
    const ids = getDefaultAlbumsIds(newAlbums);
    const masterGogData = await getMasterGogData();
    const albums = masterGogData.albums.filter((x: any) =>
      ids.includes(x.albumId),
    );
    return albums;
  };

  const fetchTemplateDefaultAlbums = async (
    defaultAlbumsIds: string[],
    instance: string,
    newAlbums: boolean = false,
  ) => {
    let defaultAlbums: GogAlbum[] = [];

    const masterGoG = await getGogAlbums(
      '1a277b49-ad04-4139-a4ab-8b5ac24205a5',
      instance,
      'TPASection_ihuk8wkg',
      isViewer,
    );

    if (masterGoG) {
      let id: string, i, gmi;
      const gogMasterHash: { [id: string]: any } = {};

      // map gog master array to hash
      for (gmi = 0; gmi < masterGoG.albums.length; gmi++) {
        const a = masterGoG.albums[gmi];
        gogMasterHash[a.albumId] = a;
      }
      // build array
      for (i = 0; i < defaultAlbumsIds.length; i++) {
        id = defaultAlbumsIds[i];
        if (defAlbums[id]) {
          defaultAlbums.push(defAlbums[id]);
        } else {
          if (gogMasterHash[id]) {
            defaultAlbums.push(gogMasterHash[id]);
          }
        }
      }
    } else {
      defaultAlbums = await fetchDefaultAlbums(newAlbums);
    }
    return defaultAlbums;
  };

  return { getDefaultAlbums, fetchTemplateDefaultAlbums, getDefaultAlbumsIds };
};

export default defaultAlbumsService;
